<template>
    <div>
        <van-nav-bar title="填写日报（测试2）" left-text="返回" @click-left="back" fixed left-arrow/>

        <van-form class="login_form" @submit="submitReport">
            <van-field readonly clickable name="datetimePicker" :value="form.createDate" label="时间选择" placeholder="点击选择时间" 
            @click="showPicker = true" :rules="rules.createDate" />
            <van-popup v-model="showPicker" position="bottom">
                <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate" @confirm="changeTime" @cancel="showPicker = false"/>
            </van-popup>
            <van-cell title="总时长(h)" v-if="reportTimeType.type == 3">
                <template>
                    <van-stepper :disabled="!canEdit" v-model="reportTimeType.allday" @change="changeAllTime" min="0.5" max="12" step="0.5" :decimal-length="1" />
                </template>
            </van-cell>
            
            <!-- <van-cell title="待分配时长" :value="report.time + 'h'" size="large"></van-cell> -->

            <div class="form_domains" v-for="(item,index) in form.domains" :key="item.id">
                <div style="float:right;margin-top:10px;margin-right:10px;">
                <!-- <van-tag v-if="canEdit&&item.projectName.length>0" color="#fff"
                @click="copyProject(index)" style="border: 1px solid #20a0ff;padding:5px;"
                 icon="plus" type="default" ><span style="color:#666;padding: 0 5px;">复制项目</span></van-tag> -->
                
                <van-tag v-if="index>0&&canEdit" color="#fff" 
                @click="delPro(index)" style="border: 1px solid #ff0000;padding:5px;margin-left:10px;"
                 icon="plus" type="default" ><span style="color:#666;padding: 0 5px;">删除项目</span></van-tag>
                </div>
                <!-- <van-icon v-if="index>0&&canEdit" class="form_del" name="delete" @click="delPro(index)" /> -->

                <van-cell-group :title="'项目' + (index+1)">
                    <!-- <div>请选择投入项目</div> -->
                    <van-field  readonly  name="projectId" clickable :value="item.projectName" label="投入项目" placeholder="请选择投入项目" @click="clickPicker(index, item)"
                    :rules="[{ required: true, message: '请选择项目' }]" />

                    <van-field  readonly  name="projectAuditorId" v-if="item.auditUserList != null && item.auditUserList.length > 0" clickable
                        :value="item.projectAuditorName" label="项目审核人" placeholder="请选择审核人" 
                    @click="clickPickAuditor(index, item)" />
                    <van-popup v-model="item.showPickerAuditor" position="bottom">
                        <van-picker show-toolbar :columns="item.auditUserList" value-key="auditorName" @confirm="choseAuditor" 
                            @cancel="item.showPickerAuditor = false;$forceUpdate();" />
                    </van-popup>

                    <van-field  readonly  name="subProjectId" v-if="item.subProjectList != null && item.subProjectList.length > 0" clickable 
                        :value="item.subProjectName" label="子项目" placeholder="请选择子项目" 
                    @click="clickPickSubProject(index, item)" />

                    <van-popup v-model="item.showPickerSubProject" position="bottom">
                        <van-picker show-toolbar :columns="item.subProjectList" value-key="name" @confirm="choseSubProject" 
                            @cancel="item.showPickerSubProject = false;$forceUpdate();" />
                    </van-popup>
                    <!--任务分组 -->
                    <van-field  readonly  name="groupId" v-if="user.company.packageProject==1&&item.taskGroups != null && item.taskGroups.length > 0" clickable 
                        :value="item.groupName" label="任务分组" placeholder="请选择任务分组" 
                    @click="clickPickTaskGroup(index, item)" />

                    <van-popup v-model="item.showPickerTaskGroup" position="bottom">
                        <van-picker show-toolbar :columns="item.taskGroups" value-key="name" @confirm="choseTaskGroup" 
                            @cancel="item.showPickerTaskGroup = false;$forceUpdate();" />
                    </van-popup>
                    <!--任务阶段 -->
                    <van-field  readonly  name="stage" v-if="user.company.packageProject==1&&item.stages != null && item.stages.length > 0" clickable 
                        :value="item.stage" label="投入阶段" placeholder="请选择投入阶段" 
                    @click="clickPickStage(index, item)" />

                    <van-popup v-model="item.showPickerStage" position="bottom">
                        <van-picker show-toolbar :columns="item.stages" value-key="stagesName" @confirm="choseStage" 
                            @cancel="item.showPickerStage = false;$forceUpdate();" />
                    </van-popup>

                    <!-- 相关维度 -->
                    <van-field :value="item.weiduName" v-if="item.projectId && user.timeType.customDegreeActive == 1"  readonly  name="id" clickable :label="user.timeType.customDegreeName" placeholder="请选择" @click="clickPickers(index)"/>
                    <van-popup v-model="item.showPickDegree" position="bottom">
                        <van-picker show-toolbar :columns="item.wuduList" value-key="name" @confirm="choseProjects" @cancel="item.showPickDegree = false;$forceUpdate()" />
                    </van-popup>

                    <!-- 自定义数值 -->
                    <van-field v-if="user.timeType.customDataActive==1" type="number" :disabled="!canEdit" v-model="item.customData" :label="user.timeType.customDataName" 
                         placeholder="请输入数字" ></van-field> 

                    <van-cell title="专业进度" v-if="user.company.packageEngineering == 1">
                    </van-cell>
                    <van-field :disabled="!canEdit"
                        type="number"  :name="'progress_'+pItem.professionId" input-align="right" 
                        v-for="pItem in item.professionProgress" :key="pItem.professionId"
                        :label="'--    '+pItem.professionName"  
                    >
                    <template slot="input">
                        <van-stepper v-model="pItem.progress" integer min="0" max="100" />%
                    </template>
                    </van-field>
                    <van-field v-if="user.company.packageProject == 1"  readonly name="taskId" :value="item.taskName" label="关联任务" placeholder="请选择关联任务" @click="clickPickerTask(index)"
                    />
                    <van-popup v-model="item.showPickerTask" position="bottom">
                        <van-picker show-toolbar :columns="item.taskList" value-key="taskName" @confirm="choseTask" @cancel="item.showPickerTask = false;$forceUpdate()" />
                    </van-popup>
                    <!-- <van-field readonly clickable class="form_input" :value="item.workingTime" name="workingTime" label="工作时长" placeholder="请输入工作时长（单位：小时）"
                    :rules="[{ required: true, message: '请输入工作时长（单位：小时）' }]" @touchstart.native.stop="showNumberKey = true"/>
                    <van-number-keyboard v-model="item.workingTime" :show="showNumberKey" close-button-text="完成" extra-key="." :maxlength="4" @blur="showNumberKey = false" /> -->
                    
                    <!-- 常规选择时间的方式 -->
                    <!-- 全天上下午模式 -->
                    <div v-if="reportTimeType.multiWorktime==0">
                    <van-field v-if="reportTimeType.type < 2" readonly clickable  :value="reportTimeType.type==0?item.label:(parseFloat(item.workingTime).toFixed(1)+'h')" label="工作时长" placeholder="请选择工作时长(小时)" @click="clickTimePicker(index)"
                    :rules="[{ required: true, message: '请选择工作时长' }]"/>
                    <van-popup v-model="showPickerTime" position="bottom">
                        <van-picker show-toolbar :columns="timeType"  value-key="label" @confirm="choseTimePick" @cancel="showPickerTime = false" />
                    </van-popup>
                    
                    <!-- 选择数字时间长度模式 -->
                    <van-popup v-model="showPickerHours" position="bottom">
                        <van-picker show-toolbar :columns="timeRange" 
                        :default-index="15"
                        @confirm="choseTimePick" @cancel="showPickerHours = false" />
                    </van-popup>

                    <!-- 时间段选择模式 -->
                    <van-field readonly v-if="reportTimeType.type == 2" clickable name="datetimePicker" :value="item.startTime" label="开始时间" placeholder="点击选择时间" 
                        @click="canEdit?showStartTime = true:''" :disabled="!canEdit"
                        :rules="[{ required: true, message: '请选择开始时间' }]"
                          />
                    <van-popup v-model="showStartTime" position="bottom">
                        <van-datetime-picker
                        v-model="startTime"
                        type="time"
                        @confirm="confirmTime(item,0);"
                        @cancel="showStartTime = false"
                        :min-hour="0"
                        :max-hour="23"
                        />
                        <!-- :filter="filter" 原本这个属性在里面 -->
                    </van-popup>
                    <van-field v-if="reportTimeType.type == 2" readonly clickable name="datetimePicker" :value="item.endTime" label="结束时间" placeholder="点击选择时间" 
                        @click="canEdit?showEndTime = true:''" :disabled="!canEdit" 
                        :rules="[{ required: true, message: '请选择结束时间' }]" />
                    <van-popup v-model="showEndTime" position="bottom" >
                        <van-datetime-picker
                        v-model="endTime"
                        type="time"
                        :min-hour="0"
                        :max-hour="23"
                        @confirm="confirmTime(item,1)"
                        @cancel="showEndTime = false" 
                        />
                        <!-- :filter="filter" 原本这个属性在里面 -->
                    </van-popup>
                    <van-cell v-if="reportTimeType.type == 3" >
                        <template >
                            <div>
                        <span>用时占比</span>
                        <van-slider :disabled="!canEdit" :min="10" :step="10" style="width:120px;display:inline-block;margin-left:50px;" v-model="item.progress" :value="100" @change="item.workingTime = (reportTimeType.allday*item.progress/100).toFixed(1)" >
                        <template #button>
                            <div class="custom-button">{{ item.progress }}%</div>
                        </template>
                        </van-slider>
                        <span style="margin-left:10px;float:right;">{{item.workingTime}}小时</span>
                            </div>
                        </template>
                    </van-cell>
                    <van-field class="form_input" :disabled = "!canEdit"
                    v-model="item.content" name="content" type="textarea" label="工作事项" placeholder="请输入工作事项" 
                    rows="3" autosize  />
                    </div>
                    
                    <!-- 多个时间和工作事项的选择方式 -->
                    <div v-if="reportTimeType.multiWorktime==1">
                        <div v-for="(timeItem, tindex) in item.worktimeList" :key="tindex" style="position:relative;border:#ccc 0.5px solid;margin:7px;">
                            <van-tag v-if="tindex>0 && canEdit" style="position:absolute; right:-7px;top:-7px;z-index:10;" 
                                @click="removeTimeItem(item,tindex)">X</van-tag>
                            
                            <!-- 时间段选择模式 -->
                            <van-field readonly v-if="reportTimeType.type == 2" :clickable="canEdit" name="datetimePicker" 
                            :value="timeItem.startTime" label="开始时间" placeholder="点击选择时间" 
                            :rules="[{ required: true, message: '必填项' }]"
                                @click="canEdit?showStartDialog(timeItem):''"  />
                            
                            <van-field v-if="reportTimeType.type == 2" readonly :clickable="canEdit" name="datetimePicker" 
                            :value="timeItem.endTime" label="结束时间" placeholder="点击选择时间" 
                            :rules="[{ required: true, message: '必填项' }]"
                                @click="canEdit?showEndDialog(timeItem):''"  />
                            
                            <van-field class="form_input" :disabled="!canEdit" style="color:#333;-webkit-text-fill-color:#646566;"
                            
                                v-model="timeItem.content" name="content" type="textarea" label="工作事项" placeholder="请输入工作事项" 
                                :rules="[{ required: true, message: '必填项' }]"
                                rows="1" autosize  />
                        </div>
                        <!--时间选择器 , 做统一处理，不能放到循环里，不然会有多个公用showStartTime,最后一个会现在最上层UI,导致BUG -->
                        <van-popup v-model="showWorkStartTime" position="bottom">
                                <van-datetime-picker
                                v-model="startTime"
                                type="time"
                                @confirm="confirmWorkTime(0);"
                                @cancel="showWorkStartTime = false"
                                :min-hour="0"
                                :max-hour="23"
                                />
                                <!-- :filter="filter" 原本这个属性在里面 -->
                            </van-popup>
                        <van-popup v-model="showWorkEndTime" position="bottom" >
                                <van-datetime-picker
                                v-model="endTime"
                                type="time"
                                :min-hour="0"
                                :max-hour="23"
                                @confirm="confirmWorkTime(1)"
                                @cancel="showWorkEndTime = false" 
                                />
                                <!-- :filter="filter" 原本这个属性在里面 -->
                            </van-popup>
                    </div>
                    
                    <div style="width:100%;" v-if="canEdit&&reportTimeType.multiWorktime==1">
                    <van-tag  style="text-align:center;padding:5px;margin-left:15px;border: 1px solid #20a0ff;"
                        :disabled="!canEdit" @click="addNewWorktime(index, item)" 
                        icon="plus" color="#ffffff" ><span style="color:#999;text-align:center;padding: 0 5px;"> 添加工时  </span></van-tag>
                    </div>
                    
                    <div class="overtime" >
                        <van-checkbox :disabled="!canEdit" v-model="item.isOvertime" v-if="reportTimeType.multiWorktime !=1">加班</van-checkbox>
                        <van-tag style="position:absolute;right:10px;" v-if="isCorpWX&&canEdit" type="primary" size="large" @click="takePhoto(index)">拍照上传</van-tag>
                        <!-- <van-tag style="position:absolute;right:10px;" type="primary" size="large" @click="takePhoto(index)">拍照上传</van-tag> -->
                    </div>
                    <div style="padding:5px;text-align:center;" v-if="!isIOSystem">
                        <span v-for="(p, index) in item.pics"  :key="p" style="margin-right:15px;">
                        <div class="imgList">    
                            <van-icon v-if="canEdit && user.companyId==7" size="20" name="clear"  @click="deleteImg(item, index)" class="imgList_i"/>
                            <img  :src="p" style="width:100px; height:100px;" @click="showLargeImg(item.pics, index)"/>
                        </div>
                        </span>
                    </div>
                    <div style="padding:5px;text-align:center;" v-if="isIOSystem">
                        <span v-for="(p, index) in item.iospics"  :key="p" style="margin-right:15px;">
                        <div class="imgList">
                            <van-icon  v-if="canEdit && user.companyId==7" size="20" name="clear"  @click="deleteImg(item, index)" class="imgList_i"/>
                            <img :src="p" style="width:100px; height:100px;" @click="showLargeImg(item.iospics, index)"/>
                        </div>
                        </span>
                    </div>
                    <van-popup v-model="imgShow" position="bottom" closeable >
                        <van-swipe class="my-swipe"  indicator-color="white">
                        <van-swipe-item v-for="(picItem, index) in tmpPics" :key="index">
                            <img :src="picItem" style="width:100%;" />
                        </van-swipe-item>
                        </van-swipe>
                    </van-popup>
                </van-cell-group>
                
            </div>
            <div style="text-align:center;" >
            <van-tag v-if="canEdit" size="large" style="text-align:center;margin:10px;padding:12px;margin-bottom:120px;border: 1px solid #20a0ff;"
                :disabled="!canEdit" @click="addNewPro" 
                icon="plus" color="#ffffff" ><span style="color:#999;text-align:center;padding: 0 50px;"> + 新增项目  </span></van-tag>
            </div>  
            <div class="form_btn" style="position:fixed; bottom:0px;width:100%;">
                <div style="padding-bottom:10px;">
                    <van-button v-if="canEdit" square block type="info" @click="isDraft=0" native-type="submit" style="width:50%;float:left;"> 提交 </van-button>
                    <van-button v-if="canEdit" square block type="default" @click="isDraft=1" native-type="submit" style="width:50%;float:left;"> 暂存 </van-button>
                </div>
                 <van-button v-if="canEdit&&form.domains.length>0 && form.domains[0].id != null" 
                        square block type="default" @click="deleteReport" native-type="button" 
                    style=""> 删除 </van-button>
                <!-- <div v-if="canEdit&&form.domains.length>0 && form.domains[0].id != null"
                    style="display:block;padding-top:30px;font-size:15px;color:#666;margin:0 auto;text-align:center;padding-bottom:10px;background:#ffffff;" 
                    @click="deleteReport"> 删除 </div> -->
                <!-- <van-button v-if="canEdit" square block type="default" @click="deleteReport" native-type="button" 
                    style="margin-top:10px;"> 删除 </van-button> -->
            </div>
        </van-form>
        <div style="position:fixed; bottom:0px;width:100%;">
            <van-button  v-if="canCancel" block type="default" @click="cancel"> 撤销 </van-button>
        </div>
        
        <div class="form_tip" v-if="!canEdit && !canCancel"> 已审核无法修改 </div>

        <!-- 选择项目弹窗 -->
        <van-popup v-model="showPickerUserddp" position="bottom" style="height: 80%">
            <!-- <van-search v-model="userName" placeholder="输入项目名称搜索" @clear="sea()" @blur="sea()" @search="sea()"></van-search> -->
            <van-search v-model="userName" placeholder="请输入项目名称" @clear="sea()" @blur="sea()" @search="sea()"/>
            <div style="minHeight:300px;">
                <div v-for="(item, index) in projectss" :key="item.id" class="ryuan" @click="fZr(item, index)">{{item.projectName}}</div>
            </div>
        </van-popup> 
    </div>
</template>
<script>
    // Vue.prototype.$wx = wx
    export default {
        data() {
            return {

                showPickerStage: false,
                showPickerTaskGroup: false,
                showPickerSubProject: false,
                isDraft:0,
                showWorkStartTime:false,
                showWorkEndTime:false,
                curWorktime:null,
                isIOSystem:false,
                imgShow: false,
                isCorpWX:false,
                isWX: false,
                showPickerTask:false,
                canCancel:false,
                canEdit:false,
                showEndTime: false,
                showStartTime: false,
                startTime:'09:00',
                endTime: '18:00',
                nowTime:new Date(),
                showPickerHours: false,
                timeRange:[0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.5,13.0,13.5,14.0,14.5,15.0],
                selectTime:null,
                reportTimeType:{},
                user: JSON.parse(localStorage.userInfo),
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()+7),
                currentDate: new Date(),
                showPickerTime: false,
                showPicker: false,
                showPickerProject: false,
                showPickDegree: false,
                clickIndex: 0,
                clickTimeIndex: 0,
                showNumberKey: false,
                canClick: 2,
                timeType:[],
                form: {
                    createDate: this.format(new Date(new Date()),"yyyy-MM-dd"),
                    domains: [{
                        id: null,
                        projectId: "",
                        projectName: "",
                        workingTime: "",
                        content: "",
                        state: 2,
                        multiWorktime:0,
                        worktimeList:{},
                        degreeId: ''
                        // pics:["https://worktime.ttkuaiban.com/upload/bc4df504fa724e6cab69872e2c1cfb35.png",
                        // "https://worktime.ttkuaiban.com/upload/bc4df504fa724e6cab69872e2c1cfb35.png",
                        // "https://worktime.ttkuaiban.com/upload/bc4df504fa724e6cab69872e2c1cfb35.png",]
                    }],
                },
                rules: {
                    createDate: [{ required: true, message: '请选择填报日期' }],
                },
                project: [],
                report: "",

                loading: false,
                finished: false,
                // isOvertime: false
                tmpPics:[],
                dateAr: [],
                showPickerUserddp: false, // 选择项目弹窗
                projectss: [],
                proads: [],
                userName: '',
                proIdx: ''
            };
        },

        created() {

        },

        methods: {
            //获取项目审核人
            getProjectAuditorList(domainItem) {
                this.$axios.post("/project-auditor/getList", {projectId: domainItem.projectId})
                .then(res => {
                    if(res.code == "ok") {
                        domainItem.auditUserList = res.data;
                        if (res.data.length==1) {
                            domainItem.projectAuditorId = domainItem.auditUserList[0].auditorId;
                            domainItem.projectAuditorName = domainItem.auditUserList[0].auditorName;
                        }
                        this.$forceUpdate();
                    } else {
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            updateTxt() {
                this.$forceUpdate();
            },
            // 获取项目
            getPeoject() {
                this.$axios.post("/project/getProjectList", {})
                .then(res => {
                    if(res.code == "ok") {
                        this.projectss = res.data;
                        this.projectss = this.projectss.filter(p=>p.status == 1);
                        this.proads = res.data
                    } else {
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },
            sea() {
                console.log(this.userName.length)
                if(this.userName.length > 0) {
                    console.log(123)
                    let text = this.userName
                    let reg = new RegExp(text)
                    let data = this.projectss.filter(item => reg.test(item.projectName)) //返回
                    this.projectss = data
                } else {
                    console.log(456)
                    this.project = this.proads
                }
            },
            fZr(item,index) {
                var domainItem = this.form.domains[this.proIdx];
                domainItem.projectId = item.id;
                domainItem.projectName = item.projectName;
                
                //清空子项目
                domainItem.subProjectId = null;
                domainItem.subProjectName = null;
                //清空任务
                domainItem.taskId = null;
                domainItem.taskName = null;
                //清空分组和阶段
                domainItem.stage = null;
                domainItem.groupId = null;

                this.showPickerUserddp = false;
                //获取子项目
                this.getSubprojectList(this.form.domains[this.proIdx]);
                
                //加载项目相关的工程进度
                if (this.user.company.packageEngineering == 1){
                    this.getProjectProfessions(this.form.domains[this.proIdx],index);
                }
                //获取任务分组
                this.getTaskGroups(this.form.domains[this.proIdx], this.proIdx);

                // 获取1相关维度
                this.getTaskList(domainItem.projectId)

                //获取相关日报提交选择人
                domainItem.projectAuditorId = null;
                domainItem.projectAuditorName = null;
                domainItem.auditUserList = null;
                this.getProjectAuditorList(domainItem);
            },
            //获取项目下的任务分组
            getTaskGroups(domainItem, index) {
                domainItem.groupId=null;
                this.$axios.post("/task-group/list", {projectId: domainItem.projectId})
                    .then(res => {
                        if(res.code == "ok") {
                            domainItem.taskGroups = res.data;
                        } 
                        this.$forceUpdate();
                    }).catch(err=> {this.$toast.clear();});
            },
            getSubprojectList(domainItem) {
                domainItem.subProjectList = [];
                this.$axios.post("/sub-project/list", {projectId: domainItem.projectId})
                    .then(res => {
                        if(res.code == "ok") {
                            domainItem.subProjectList = res.data;
                        } 
                        this.$forceUpdate();
                    }).catch(err=> {this.$toast.clear();});
            },
            showEndDialog(timeItem) {
                this.curWorktime = timeItem;
                this.showWorkEndTime = true;
            },
            showStartDialog(timeItem) {
                this.curWorktime = timeItem;
                this.showWorkStartTime = true;
            },
            removeTimeItem(item, index) {
                item.worktimeList.splice(index, 1);
            },
            isIOS(){
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                return isiOS;
            },
            
            showLargeImg(item, index) {
                this.imgShow = true;
                this.tmpPics = item;
            },
            //拍照上传
            takePhoto(index) {
                var that = this;
                wx.chooseImage({
                    count: 9, // 默认9
                    sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
                    defaultCameraMode: "batch", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
                    isSaveToAlbum: 0, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
                    success: function (res) {
                        var localIds = res.localIds; // 返回选定照片的本地ID列表，
                                // andriod中localId可以作为img标签的src属性显示图片；
                                // iOS应当使用 getLocalImgData 获取图片base64数据，从而用于img标签的显示（在img标签内使用 wx.chooseImage 的 localid 显示可能会不成功）
                        if (that.form.domains[index].pics == null) {
                            that.form.domains[index].pics = [];
                            that.form.domains[index].iospics = [];
                        }
                        if (that.isIOSystem) {
                            for (var i=0;i<localIds.length; i++) {
                                wx.getLocalImgData({
                                    localId: localIds[i], // 图片的localID
                                    success: function (res) {
                                        var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                                        that.form.domains[index].iospics.push(localData);
                                        that.$forceUpdate();
                                    }
                                });
                            }
                            
                            if (that.user.companyId == 7) {
                                that.form.domains[index].pics = that.form.domains[index].pics.concat(localIds); 
                            } else {
                                that.form.domains[index].pics = localIds;
                            }
                        } else {
                            if (that.user.companyId == 7) {
                                that.form.domains[index].pics = that.form.domains[index].pics.concat(localIds); 
                            } else {
                                that.form.domains[index].pics = localIds;
                            }
                            that.$forceUpdate();                            
                        }
                        // var serverIdList = [];
                        if (that.form.domains[index].serverPics == null) {
                            that.form.domains[index].serverPics = [];
                        }
                        //立即就上传到企业微信服务器
                        for (var i=0;i<localIds.length; i++) {
                            wx.uploadImage({
                                localId: localIds[i], // 需要上传的图片的本地ID，由chooseImage接口获得
                                isShowProgressTips: 1, // 默认为1，显示进度提示
                                success: function (res) {
                                    var serverId = res.serverId; // 返回图片的服务器端ID
                                    // serverIdList.push(serverId);
                                    that.form.domains[index].serverPics.push(serverId);
                                }
                            });
                        }
                    }
                });
            },
            copyProject(index) {
                var leftProgress = 10;
                if (this.reportTimeType.type == 3) {
                    //计算已经待分配工时比例
                    let array = this.form.domains;
                    let totalProgress = 0;
                    for (var i=0;i<array.length; i++) {
                        totalProgress += array[i].progress;
                    }
                    if (totalProgress < 100) {
                        leftProgress = 100 - totalProgress;
                    }
                }
                var newIndex = index+1;
                var pName = "";
                if (this.form.domains[index].projectId != '') {
                    pName = this.project.filter(p=>p.id == this.form.domains[index].projectId)[0].projectName;
                }
                var itemDomain = {
                    id: null,
                    projectId: this.form.domains[index].projectId,
                    projectName: pName,
                    workingTime: this.reportTimeType.type==3?(leftProgress*this.reportTimeType.allday/100).toFixed(1):"",
                    progress:leftProgress,
                    content: "",
                    state: 2,
                    isOvertime:false,
                };
                this.form.domains.splice(newIndex, 0,itemDomain);
            },
            //删除日报
            deleteReport() {
                this.$dialog.confirm({
                    title: '删除日报',
                    message: '确定要删除当天日报吗?'
                }).then(() => {
                    const toast = this.$toast.loading({
                        forbidClick: true,
                        duration: 0
                    });
                    this.$axios.post("/report/delete", {userId: this.user.id, date:this.form.createDate})
                    .then(res => {
                        if(res.code == "ok") {
                            this.$toast.clear();
                            this.$toast.success('删除成功');
                            window.location.reload();
                        } else {
                            this.$toast.clear();
                            this.$toast.fail('删除失败');
                        }
                    }).catch(err=> {this.$toast.clear();});
                }).catch(() => {});
            },
            changeAllTime() {
                //总时长发生改变，自动按比例计算
                this.form.domains.forEach(d=>{
                    d.workingTime = (d.progress*this.reportTimeType.allday/100).toFixed(1);
                });
            },
            cancel() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                var ids = '';
                var data = this.form.domains;
                data.forEach(element => {
                    if (element.id != null && element.id != '') {
                        ids +=(element.id+',');
                    }
                });
                this.$axios.post("/report/cancel", {userId: this.user.id, reportIds:ids})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.$toast.success('撤销成功');
                        this.getReport();
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            confirmWorkTime(field) {
                if (field == 0) {
                    this.curWorktime.startTime = this.startTime;
                    this.showWorkStartTime = false;
                } else {
                    this.curWorktime.endTime = this.endTime;
                    this.showWorkEndTime = false;
                }
            },

            confirmTime(item, field) {
                if (field == 0) {
                    item.startTime = this.startTime;
                    this.showStartTime = false;
                } else {
                    item.endTime = this.endTime;
                    this.showEndTime = false;
                }
            },
            filter(type, options) {
                if (type === 'minute') {
                    return options.filter(option => option % 30 === 0);
                }

                return options;
            },
            choseTimePick(value, index) {
                //选中时间
                if (this.reportTimeType.type == 0) {
                    this.form.domains[this.clickTimeIndex].timeType = value.value;
                    this.form.domains[this.clickTimeIndex].workingTime = value.hours;
                    this.form.domains[this.clickTimeIndex].label = value.label;
                    this.showPickerTime = false;
                } else if (this.reportTimeType.type == 1) {
                    console.log('this.reportTimeType.type=='+value);
                    this.form.domains[this.clickTimeIndex].workingTime = value;
                    this.form.domains[this.clickTimeIndex].label = value.toFixed(1)+'小时';
                    this.showPickerHours = false;
                }
      
            },
            clickTimePicker(i) {
                if (!this.canEdit) {
                    return;
                }
                this.clickTimeIndex = i;
                
                if (this.reportTimeType.type == 0) {
                    this.showPickerTime = true;
                } else if (this.reportTimeType.type == 1) {
                    this.showPickerHours = true;
                }
            },
            getTimeType() {
                this.$axios.post('/time-type/getCompanyTimeSetting', { companyId: this.user.companyId})
                .then(res => {
                    if(res.code == "ok") {
                        var t = res.data;
                        this.reportTimeType = t;
                        //转化时间格式
                        if (t.allday != null) {
                            this.timeType.push({value:0, label:'全天 - '+t.allday+'小时', hours:t.allday});
                        }
                        if (t.am != null) {
                            this.timeType.push({value:1, label:'上午 - '+t.am+'小时', hours: t.am});
                        }
                        if (t.pm != null) {
                            this.timeType.push({value:2, label:'下午 - '+t.pm+'小时', hours: t.pm});
                        }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            // 返回
            back() {
                history.back();
            },

            // 时间转换
            format(date, pattern) {
                pattern = pattern || "yyyy-MM-dd";
                var _this = this;
                return pattern.replace(/([yMdhsm])(\1*)/g, function ($0) {
                    switch ($0.charAt(0)) {
                        case 'y': return _this.padding(date.getFullYear(), $0.length);
                        case 'M': return _this.padding(date.getMonth() + 1, $0.length);
                        case 'd': return _this.padding(date.getDate(), $0.length);
                        case 'w': return date.getDay() + 1;
                        case 'h': return _this.padding(date.getHours(), $0.length);
                        case 'm': return _this.padding(date.getMinutes(), $0.length);
                        case 's': return _this.padding(date.getSeconds(), $0.length);
                    }
                });
            },

            padding(s, len) {
                var len = len - (s + '').length;
                for (var i = 0; i < len; i++) { s = '0' + s; }
                return s;
            },

            // 获取项目
            getProject() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/project/getProjectList", {})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.project = res.data;
                        
                        // if (this.project.length > 0) {
                        //     console.log('this.project[0].id=='+this.project[0].id);
                        //     this.getTaskList(this.project[0].id);
                        // }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            // 获取日报
            getReport() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/report/getReport", {date: this.form.createDate})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.report = res.data;
                        var t = res.data.timeType;
                        var timeType=[];
                        //转化时间格式
                        if (t.allday != null) {
                            timeType.push({value:0, label:'全天 - '+t.allday+'小时', hours:t.allday});
                        }
                        if (t.am != null) {
                            timeType.push({value:1, label:'上午 - '+t.am+'小时', hours: t.am});
                        }
                        if (t.pm != null) {
                            timeType.push({value:2, label:'下午 - '+t.pm+'小时', hours: t.pm});
                        }
                        var list = res.data.report;
                        if(list.length != 0) {
                            this.canEdit = false;
                            this.canCancel = false;
                            let array = [];
                            for(var i in list) {
                                var projectName = "";
                                var flg = (list[i].isOvertime == 1);
                                for(var j in this.project) {
                                    if(this.project[j].id == list[i].projectId) {
                                        projectName = this.project[j].projectName;
                                    }
                                }
                                let tname = '';
                                if (list[i].taskId != null && list[i].taskList.length > 0) {
                                    let filterList = list[i].taskList.filter(t=>t.taskId == list[i].taskId);
                                    if (filterList.length > 0) {
                                        tname = filterList[0].taskName;
                                    }
                                } 
                                let mmm = list[i].degreeId
                                let sss = list[i].degreeList
                                let wuduName = ''
                                for (var s in sss) {
                                    if(sss[s].id == mmm) {
                                        wuduName = sss[s].name
                                    }
                                }
                                let serverPicArray = [];
                                console.log('picArray=='+list[i].picStr);
                                if (list[i].picStr != null && list[i].picStr != '@') {
                                    serverPicArray = JSON.parse(list[i].picStr.replace(/@/g,","));
                                }
                                
                                var subProjectName = null;
                                if (list[i].subProjectId) {
                                    subProjectName = list[i].subProjectList.filter(s=>s.id==list[i].subProjectId)[0].name;
                                }
                                array.push({
                                    id: list[i].id,
                                    projectId: list[i].projectId,
                                    projectName: projectName,
                                    workingTime: String(list[i].workingTime),
                                    content: list[i].content,
                                    state: list[i].state,
                                    timeType: list[i].timeType,
                                    label: timeType[list[i].timeType].label,
                                    startTime: list[i].startTime,
                                    endTime: list[i].endTime,
                                    isOvertime: flg,
                                    progress: list[i].progress,
                                    taskList: list[i].taskList,
                                    taskId: list[i].taskId ? list[i].taskId : null,
                                    taskName:tname,
                                    professionProgress:list[i].professionProgressList,
                                    pics: list[i].pics,
                                    iospics:list[i].pics,
                                    serverPics:serverPicArray,
                                    multiWorktime:t.multiWorktime,
                                    worktimeList:list[i].worktimeList,
                                    wuduList: list[i].degreeList,
                                    weiduName: wuduName,
                                    degreeId: list[i].degreeId,
                                    subProjectId: list[i].subProjectId,
                                    subProjectName: subProjectName,
                                    subProjectList: list[i].subProjectList,
                                    groupId: list[i].groupId == 0?null:list[i].groupId,
                                    groupName: list[i].groupName,
                                    taskGroups:list[i].taskGroups,
                                    stages:list[i].stages,
                                    stage:list[i].stage == '-'?null:list[i].stage,
                                    customData: list[i].customData,
                                    projectAuditorId: list[i].projectAuditorId,
                                    projectAuditorName: list[i].projectAuditorName,
                                    auditUserList: list[i].auditUserList,
                                })
                                if (list[i].state >= 2) {
                                    this.canEdit = true;
                                } else if (list[i].state == 0) {
                                    this.canCancel = true;
                                }
                            }
                            this.form.domains = array;
                        } else {
                            this.canCancel = false;
                            this.canEdit = true;
                            //没有填报的可以点击提交
                            this.form.domains = [{
                                id: null,
                                projectId: "",
                                projectName: "",
                                workingTime: t.type==3?(t.allday).toFixed(1):"8.0",
                                content: "",
                                state: 2,
                                progress:100,
                                isOvertime:false,
                                professionProgress:[],
                                multiWorktime:t.multiWorktime,
                                worktimeList:[{}],  
                            }]
                            this.canEdit = true;
                        }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                    if(localStorage.getItem("formVal")) {
                        console.log('触发了')
                        var obj = localStorage.getItem("formVal")
                        this.form = JSON.parse(obj)
                        localStorage.removeItem('formVal')
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            // 改变时间
            changeTime(time) {
                this.form.createDate = this.format(new Date(time),"yyyy-MM-dd");
                this.currentDate = time;
                this.showPicker = false;
                this.getReport();
            },

            //点击选择审核人
            clickPickAuditor(i, item) {
                if (!this.canEdit) return;
                if (item.auditUserList.length<=1) return;
                this.clickIndex = i;
                item.showPickerAuditor = true;
                this.$forceUpdate();
            },

            choseAuditor(value, index) {
                this.form.domains[this.clickIndex].projectAuditorId = value.auditorId;
                this.form.domains[this.clickIndex].projectAuditorName = value.auditorName;
                this.form.domains[this.clickIndex].showPickerAuditor = false;
                this.$forceUpdate();
            },

            //点击选择子项目
            clickPickSubProject(i, item) {
                if (!this.canEdit) return;
                this.clickIndex = i;
                item.showPickerSubProject = true;
                this.$forceUpdate();
            },
            clickPickTaskGroup(i, item) {
                if (!this.canEdit) return;
                this.clickIndex = i;
                item.showPickerTaskGroup = true;
                this.$forceUpdate();
            },
            clickPickStage(i, item) {
                if (!this.canEdit) return;
                this.clickIndex = i;
                item.showPickerStage = true;
                this.$forceUpdate();
            },

            // 选择项目
            clickPicker(i, item) {
                if (!this.canEdit) return;
                // this.clickIndex = i;
                // this.showPickerProject = true;
                console.log(i, item, this.form)
                this.proIdx = i
                this.showPickerUserddp = true
                // this.$router.push({
                //     path: '/search',
                //     query: {
                //         // from: JSON.stringify(this.form)
                //         from: this.form,
                //         idx: i
                //     }
                // });
            },
            clickPickers(i) {
                if (!this.canEdit) return;
                this.clickIndex = i;
                this.form.domains[this.clickIndex].showPickDegree = true;
                var proId = this.form.domains[this.clickIndex].projectId
                this.dimension(proId)
            },
            //选择任务
            clickPickerTask(i) {
                if (!this.canEdit) return;
                this.clickIndex = i;
                // var proId = this.form.domains[this.clickIndex].projectId
                // this.getTaskList(proId)
                this.form.domains[this.clickIndex].showPickerTask = true;
                this.$forceUpdate();
            },
            choseTask(value, index) {
                this.form.domains[this.clickIndex].taskId = value.taskId;
                this.form.domains[this.clickIndex].taskName = value.taskName;
                this.form.domains[this.clickIndex].showPickerTask = false;
                this.$forceUpdate();
            },

            choseProjects(value, index) {
                // console.log(value)
                this.form.domains[this.clickIndex].weiduName = value.name;
                this.form.domains[this.clickIndex].degreeId = value.id;
                this.form.domains[this.clickIndex].showPickDegree = false;
                this.$forceUpdate();
            },

            choseSubProject(value, index) {
                this.form.domains[this.clickIndex].subProjectId = value.id;
                this.form.domains[this.clickIndex].subProjectName = value.name;
                this.form.domains[this.clickIndex].showPickerSubProject = false;
                this.$forceUpdate();
            },

            choseTaskGroup(value, index) {
                this.form.domains[this.clickIndex].groupId = value.id;
                this.form.domains[this.clickIndex].groupName = value.name;
                this.form.domains[this.clickIndex].showPickerTaskGroup = false;
                
                this.getGroupStages(this.form.domains[this.clickIndex], index);
                // this.getTaskList(value.id);
                // this.dimension(value.id) // 获取维度
                
                // //加载项目相关的工程进度
                // if (this.user.company.packageEngineering == 1) {
                //     this.getProjectProfessions(this.form.domains[this.clickIndex], index);
                // }
            },
            choseStage(value, index) {
                this.form.domains[this.clickIndex].stage = value.stagesName;
                this.form.domains[this.clickIndex].showPickerStage = false;
                this.$forceUpdate();

            },

            getGroupStages(domain, index) {
                this.$axios.post("/stages/getProjectStagesByGroup", {groupId: domain.groupId})
                        .then(res => {
                            if(res.code == "ok") {
                                domain.stage = '';
                                domain.stages = res.data;
                                this.$forceUpdate();
                            } else {
                                this.$toast.fail('获取失败');
                            }
                        }).catch(err=> {this.$toast.clear();});
            },


            getProjectProfessions(domain, index) {
                console.log(domain, '数据')
                this.$axios.post("/project-profession/getMyProfession", {projectId: domain.projectId})
                        .then(res => {
                            if(res.code == "ok") {
                                this.form.domains[this.clickIndex].professionProgress = res.data;
                                console.log(this.form.domains)
                                this.$forceUpdate();
                            } else {
                                this.$toast.fail('获取失败');
                            }
                        }).catch(err=> {this.$toast.clear();});
            },
            // 获取维度
            dimension(projectId) {
                this.$axios.post("/project/getDegreeList", {projectId: projectId})
                .then(res => {
                    if(res.code == "ok") {
                        this.form.domains[this.clickIndex].wuduList = res.data;
                        this.$forceUpdate();
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },


            getTaskList(projectId) {
                //如果是专业版，需要列出任务列表
                if (this.user.company.packageProject == 1) {
                    this.$axios.post("/task/getRecentTask", {projectId: projectId})
                        .then(res => {
                            if(res.code == "ok") {
                                this.form.domains[this.clickIndex].taskList = res.data;
                                this.form = this.form;
                                // this.$forceUpdate();
                            } else {
                                this.$toast.fail('获取失败');
                            }
                        }).catch(err=> {this.$toast.clear();});
                }
            },
            //添加工时
            addNewWorktime(index, item) {
                item.worktimeList.push({});
            },

            // 添加项目 
            addNewPro() {
                var leftProgress = 10;
                if (this.reportTimeType.type == 3) {
                    //计算已经待分配工时比例
                    let array = this.form.domains;
                    let totalProgress = 0;
                    for (var i=0;i<array.length; i++) {
                        totalProgress += array[i].progress;
                    }
                    if (totalProgress < 100) {
                        leftProgress = 100 - totalProgress;
                    }
                }

                this.form.domains.push({
                    id: null,
                    projectId: "",
                    projectName: "",
                    workingTime: this.reportTimeType.type==3?(leftProgress*this.reportTimeType.allday/100).toFixed(1):"4.0",
                    progress:leftProgress,
                    content: "",
                    state: 2,
                    isOvertime:false,
                    multiWorktime: this.reportTimeType.multiWorktime,
                    worktimeList:[{}],
                    degreeId: null
                })
            },

            // 移除项目
            delPro(i) {
                this.$dialog.confirm({
                    title: '',
                    message: '是否移除当前项目'
                }).then(() => {
                    this.form.domains.splice(i,1);
                }).catch(() => {
                });
            },
            // 判断时间段
            fns() {
                for (let k in this.dateAr) {
                    if (!this.judege(k)) {
                    return false
                    }
                }
                return true
            },
            judege(idx){
                for (let k in this.dateAr) {
                    if (idx !== k) {
                        if (this.dateAr[k].s <= this.dateAr[idx].s && this.dateAr[k].e > this.dateAr[idx].s) {
                        return false
                        }
                        if (this.dateAr[k].s < this.dateAr[idx].e && this.dateAr[k].e >= this.dateAr[idx].e) {
                        return false
                        }
                    }
                }
                return true
            },

            // 提交日报
            submitReport() {
                this.dateAr = []
                let alp = []
                if(this.user.timeType.multiWorktime == 1) {
                    for(var p in this.form.domains) {
                        var slll = this.form.domains[p]
                        for(var o in slll.worktimeList) {
                            if(slll.worktimeList[o].startTime || slll.worktimeList[o].endTime) {
                                let objs = {}
                                objs.s = slll.worktimeList[o].startTime
                                objs.e = slll.worktimeList[o].endTime
                                alp.push(objs)
                            }
                        }
                    }
                    this.dateAr = alp
                    if(this.dateAr.length > 0) {
                        let trus = this.fns()
                        if(!trus) {
                            this.$toast('填写的时间段重叠')
                            return
                        }
                    }
                }
                // return
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                // let formData = new FormData();
                let formData = new URLSearchParams()
                formData.append("draft", this.isDraft);
                if (this.reportTimeType.type == 0) {
                    var alldayNum = 0;
                    var amNum = 0;
                    var pmNum = 0;
                    for(var i in this.form.domains) {
                        if (this.form.domains[i].timeType == 0) {
                            alldayNum ++;
                        } else if (this.form.domains[i].timeType == 1) {
                            amNum++;
                        } else if (this.form.domains[i].timeType == 2) {
                            pmNum++;
                        }
                    }
                    if (alldayNum > 1) {
                        this.$toast.fail("工作时间-全天，只能选择一次");
                        return;
                    }
                    if (amNum > 1) {
                        this.$toast.fail("工作时间-上午，只能选择一次");
                        
                        return;
                    }
                    if (pmNum > 1) {
                        this.$toast.fail("工作时间-下午，只能选择一次");
                        return;
                    }
                    if (alldayNum == 1 && (amNum > 0 || pmNum > 0)) {
                        this.$toast.fail("工作时间-全天，不能和上下午同时存在");
                        return;
                    }
                } else if (this.reportTimeType.type == 3) {
                    //总百分比不能超过100%
                    let total = 0;
                    this.form.domains.forEach(w=>{total += w.progress});
                    if (total > 100) {
                        this.$toast.fail("用时比例之和不能超过100%");
                        return;
                    } else if (total < 100) {
                        this.$toast.fail("工时尚未完全分配，无法提交");
                        return;
                    }
                }
                
                //填字段
                for(var i in this.form.domains) {
                    if (this.user.timeType.customDegreeActive == 1) {
                        if(this.form.domains[i].degreeId) {
                            formData.append("degreeId", this.form.domains[i].degreeId);
                        } else {
                            formData.append("degreeId", -1);
                        }
                    } else {
                        formData.append("degreeId", -1);
                    }
                    if(this.form.domains[i].customData) {
                        formData.append("customData", this.form.domains[i].customData);
                    } else {
                        formData.append("customData", "0");
                    }
                    
                    if (this.form.domains[i].id) {
                        formData.append("id", this.form.domains[i].id);
                    } else {
                        formData.append("id", -1);
                    }
                    formData.append("projectId", parseFloat(this.form.domains[i].projectId));
                    if (this.form.domains[i].subProjectId) {
                        formData.append("subProjectId", this.form.domains[i].subProjectId);
                    } else {
                        formData.append("subProjectId", 0);
                    }
                    if (this.form.domains[i].groupId) {
                        formData.append("groupId", this.form.domains[i].groupId);
                    } else {
                        formData.append("groupId", 0);
                    }
                    if (this.form.domains[i].stage) {
                        formData.append("stage",  this.form.domains[i].stage);
                    } else {
                        formData.append("stage", '-');
                    }
                    formData.append("reportTimeType", this.reportTimeType.type);
                    formData.append("multiWorktime", this.reportTimeType.multiWorktime);
                    if (this.reportTimeType.type == 0) {
                        formData.append("timeType", this.form.domains[i].timeType);
                        formData.append("workingTime", parseFloat(this.form.domains[i].workingTime));
                    } else if (this.reportTimeType.type == 1) {
                        formData.append("workingTime", parseFloat(this.form.domains[i].workingTime));
                    } else if (this.reportTimeType.type == 2) {
                        formData.append("startTime", this.form.domains[i].startTime);
                        formData.append("endTime",this.form.domains[i].endTime);
                    } else if (this.reportTimeType.type == 3) {
                        formData.append("progress", this.form.domains[i].progress);
                        formData.append("workingTime",this.form.domains[i].workingTime);
                    }
                    
                    if (this.form.domains[i].taskId) {
                        formData.append("taskId", this.form.domains[i].taskId);
                    } else {
                        formData.append("taskId", 0);
                    }
                    formData.append("createDate", this.form.createDate);
                    formData.append("isOvertime", this.form.domains[i].isOvertime?1:0);

                    //项目专业进度
                    if (this.form.domains[i].professionProgress) {
                        let m = JSON.stringify(this.form.domains[i].professionProgress);
                        m = m.replace(/,/g,"@");//replaceAll(',','@');企业微信不兼容replaceAll
                        formData.append("professionProgress", m);
                    } else {
                        formData.append("professionProgress", "[]");
                    }
                    //图片的处理，serverPic是当前所有的照片
                    if (this.form.domains[i].serverPics) {
                        let m = JSON.stringify(this.form.domains[i].serverPics);
                        m = m.replace(/,/g,"@");//replaceAll(',','@');企业微信不兼容replaceAll
                        formData.append("pics", m);
                    } else {
                        formData.append("pics", "@");
                    }
                    
                    //处理多个时间事项
                    if (this.reportTimeType.multiWorktime == 1) {
                        //检查时间是否有重叠
                        var workList = this.form.domains[i].worktimeList;
                        for (var j=0;j<workList.length; j++) {
                            var curItem = workList[j];
                            if (curItem.startTime == null || curItem.endTime == null) {
                                this.$toast.fail("请设置工作时间");
                                return;
                            }
                            //检查开始时间是否大于结束时间
                            if (curItem.startTime >= curItem.endTime) {
                                this.$toast.fail("时间段"+curItem.startTime+'-'+curItem.endTime+"有误："+ 
                                        "结束时间必须大于开始时间");
                                return;
                            }
                            for (var p = j+1;p<workList.length; p++) {
                                var jItem = workList[p];
                                if ((jItem.startTime>=curItem.startTime&&jItem.startTime < curItem.endTime)
                                        || (jItem.endTime>curItem.startTime&&jItem.endTime <= curItem.endTime)) {
                                    
                                    this.$toast.fail("时间段"+curItem.startTime+'-'+curItem.endTime+"与"+ 
                                            jItem.startTime+'-'+jItem.endTime+ "存在重叠，请修改。");
                                    return;
                                }
                            }
                        }

                        let m = JSON.stringify(this.form.domains[i].worktimeList);
                        m = m.replace(/,/g,"@");//replaceAll(',','@');企业微信不兼容replaceAll
                        formData.append("content", m);
                    } else {
                        if (this.form.domains[i].content) {
                            formData.append("content", this.form.domains[i].content);
                        } else {
                            formData.append("content", '-');
                        }
                    }

                    //审核人
                    if (this.form.domains[i].projectAuditorId) {
                        formData.append("projectAuditorId", this.form.domains[i].projectAuditorId);
                    } else {
                        this.$toast.fail("请指定["+this.form.domains[i].projectName+']项目的审核人');
                        return;
                    }
                }
                
                var valjdk = ''
                for (var key of formData.keys()) {
                        console.log(key + "-------" + formData.get(key));
                        valjdk = valjdk + key + "-------" + formData.get(key) + '\n'
                    }
                    var sss = formData.get('projectId')
                    alert(sss)
                    alert(valjdk)
                // return
 
                this.$axios.post("/report/editReport", formData)
                // this.$axios.post("/report/editReport", objs)
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.$toast.success(this.isDraft==0?'提交成功':'暂存成功');
                        this.$router.push("/index");
                    } else {
                        this.$toast.clear();
                        this.$toast.fail((this.isDraft==0?'提交失败':'暂存失败')+'：'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
                // this.$axios({
                //     method: "post",
                //     url: `/report/editReport`,
                //     data: obj,
                //     // headers: {
                //     //     transformRequest: [data => data]//此处是我进行设置的转换数据类型
                //     // },
                //     headers: { "Content-Type": "multipart/form-data" }
                    
                // })
                // .then(res => {
                //     if(res.code == "ok") {
                //         this.$toast.clear();
                //         this.$toast.success(this.isDraft==0?'提交成功':'暂存成功');
                //         this.$router.push("/index");
                //     } else {
                //         this.$toast.clear();
                //         this.$toast.fail((this.isDraft==0?'提交失败':'暂存失败')+'：'+res.msg);
                //     }
                // }).catch(err=> {this.$toast.clear();});

                // axios({
                //     method: "post",
                //     url: '/report/editReport',
                //     data: formData,
                //     headers: { "Content-Type": "multipart/form-data" },
                // })
                // }
            },
            //初始化参数
            initWxConfig() {
                var curUrl=location.href.split("#")[0];
                
                this.$axios.post("/wxcorp/getCorpWXConfig", {url: curUrl, token: this.user.id})
                        .then(res => {
                            if(res.code == "ok") {
                                var data=res.data;
                                wx.config({ 
                                beta: true,
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: data.appid, // 必填，公众号的唯一标识 
                                timestamp: data.timestamp, // 必填，生成签名的时间戳 
                                nonceStr: data.noncestr, // 必填，生成签名的随机串 
                                signature: data.sign, // 必填，签名，见附录1 
                                jsApiList: [
                                    'chooseImage',
                                    'previewImage',
                                    'uploadImage',
                                    'downloadImage',
                                    'previewFile',
                                    'getLocation',
                                ] 
                                });
                                wx.ready(function(){
                                    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                                    console.log('企业微信初始化执行成功了。 ');
                                });
                            } else {
                                this.$toast.fail('获取失败');
                            }
                        }).catch(err=> {this.$toast.fail('发生异常'+err);console.log(err)});
                
            },
            deleteImg(item, index) {
                //删除添加的图片
                if (this.isIOSystem) {
                    item.iospics.splice(index,1);
                    item.iospics = item.iospics;
                }
                item.pics.splice(index,1);
                item.pics = item.pics;

                //传递到后台的数据也要做同步删除
                item.serverPics.splice(index,1);
                this.$forceUpdate();
            }
        },
        
        mounted() {
            console.log(localStorage.getItem("formVal"))
            var ua = navigator.userAgent.toLowerCase();
            this.isIOSystem = this.isIOS();
            if (ua.indexOf("wxwork") > 0) {
                this.isCorpWX = true;
            } else if (ua.indexOf("micromessenger") > 0) {
                this.isWX = true;
            }
            this.getPeoject() // 获取项目
            //获取传递过来的日期
            var passDate = this.$route.query.date;
            if (passDate != null) {
                this.form.createDate = this.$route.query.date;
            }
            
            this.getProject();
            this.getReport();
            this.getTimeType();
            //初始化微信js-sdk参数
            if (this.isCorpWX) {
                this.initWxConfig();
            }

            // if(localStorage.getItem("formVal")) {
            //     var obj = localStorage.getItem("formVal")
            //     this.form = JSON.parse(obj)
            //     localStorage.removeItem('formVal')
            // }
        },
        beforeDestroy() {
            localStorage.removeItem('formVal')
        }
    };
</script>

<style lang="less" scope>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;

}
    .van-field__control:disabled {
        -webkit-text-fill-color:#646566;
    }
    .login_form {
        margin-top: 46px;
    }

    .form_domains {
        position: relative;
        .form_copy {
            position: absolute;
            right: 85px;
            top: 10px;
        }
        .form_addNew {
            text-align: center;
            margin:10px;
        }
        .form_del {
            position: absolute;
            right: 10px;
            top: 10px;
        }
        // .form_del {
        //     color: #ff0000;
        //     font-size: 22px;
        //     position: absolute;
        //     right: 15px;
        //     top: 10px;
        // }
    }

    .form_tip {
        text-align: center;
        margin-top: 20px;
        color: #afafaf;
        font-size: 14px;
    }

    .card__footer {
        padding-top: 10px;
    }
    .card__tags {
        .van-tag {
            margin-right: 5px;
        }
    }
    
</style>
<style lang="less">
    
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
    .overtime {
        height: 50px;
        box-sizing: border-box;
        padding-left: 10px;
        display: flex;
        align-items: center;
    }
    .custom-button {
    width: 40px;
    color: #fff;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    background-color: #1989fa;
    border-radius: 100px;
  }
  .imgList {
    position: relative;
    width: 100px;
    height: 100px;
    display: inline-block;
  }
  .imgList_i {
      position: absolute;
      top: -8px;
      right: -6px;
      z-index: 999; 
  }
  .ryuan {
        padding: 0 20px;
        line-height: 40px;
        height: 40px;
        color: #333;
        border-bottom: 2px solid #f6f6f6;
        width: 100%;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
</style>


